<template>
  <div class="main-box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{path:'/'}">首页</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-form :model="queryParams" ref="queryForm" :inline="true">
        <el-form-item label="角色ID" prop="id">
          <el-input
              v-model="queryParams.id"
              clearable
              placeholder="请输入角色ID"
              size="mini"
              @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="角色名称" prop="name">
          <el-input
              v-model="queryParams.name"
              clearable
              placeholder="请输入角色名称"
              size="mini"
              @keyup.enter.native="handleQuery"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" icon="el-icon-search" @click="handleQuery">搜索</el-button>
          <el-button size="mini" icon="el-icon-refresh" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>

      <el-row>
        <el-col>
          <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
          >新增</el-button>
        </el-col>
      </el-row>

      <el-table v-loading="loading" :data="roleList">
        <el-table-column label="角色ID" prop="id"></el-table-column>
        <el-table-column label="角色名称" prop="name"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="handleUpdate(scope.row)"
            >修改
            </el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-box">
        <el-pagination
            background
            layout="total,prev, pager, next"
            :page-size="pageSize"
            :total="total"
            @current-change="currentChange"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Role",
  data() {
    return {
      loading: false,
      roleList: [],
      queryParams: {
        pageNo: 1,
        name: undefined,
        id: undefined
      },
      total: 0,
      pageSize: 10
    }
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      const {data: res} = await this.$axios.post('role/selectList', this.queryParams);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error('获取角色列表失败！')
      }
      this.roleList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },
    handleQuery() {
      this.queryParams.pageNo = 1;
      this.getList();
    },
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    currentChange(pageNo) {
      this.queryParams.pageNo = pageNo
      this.getList();
    }
  }
}
</script>

<style scoped>
.page-box {
  text-align: right;
  padding-top: 5px;;
}
</style>